body {
  font-family: 'Roboto';
  color: #444;
  font-size: 100%;
  background: #efeff4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

a {
  color: #4ca2ff;
}

/* a:hover, a:visited {
} */
